
  import { defineComponent, onMounted, ref } from "vue";
  import { setCurrentPageTitle } from "@/presentation/helper/Breadcrumb";
  import { ErrorMessage, Field, Form } from "vee-validate";
import * as Yup from "yup";
import { useI18n } from "vue-i18n";

  export default defineComponent({
    name: "interview",
    components: {
      Field,
      Form,
      ErrorMessage,
    },
    setup() {
      const { t } = useI18n();
      const url = ref("");

    //Create form validation object
    const firstForm = Yup.object().shape({
      name: Yup.string().required(t("validators_error.required")).label("Name"),
      surname: Yup.string()
        .required(t("validators_error.required"))
        .label("Surname"),
      email: Yup.string()
        .required(t("validators_error.required"))
        .email(t("validators_error.email"))
        .label("Email"),
    });
    
    const submit = (values: any) => {
      console.log(values);
      url.value = "https://www.asistan.ogzatech.com/interview/3253543"
    };

      onMounted(() => {
        setCurrentPageTitle(t("modals.interview.title"));
      });
  
      return {
        firstForm,
        submit,
        url
      };
    },
  });
  