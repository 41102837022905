<template>
    <!--begin::Row-->
    <div class="row g-5 g-xl-8">
      <div class="col-xl-12">
        <div class="card mb-xl-8">
          <!--begin::Body-->
          <div class="card-body">
            <!--begin::Heading-->
            <div class="text-center">
              <!--begin::Title-->
              <div class="card-label fw-bolder fs-3 mb-3">{{ $t("modals.interview.createInterview") }}</div>
              <!--end::Title-->
            </div>
            <!--end::Heading-->
            <!--begin::Form-->
            <Form
              id="kt_account_profile_details_form"
              class="form"
            >
              <!--begin::Card body-->
              <div class="card-body p-9 ps-6 pb-1">
                <Form
                  id="vaccinate_modal_form"
                  :validation-schema="firstForm"
                  class="form"
                  @submit="submit"
                >
                  <div class="row mb-2">
                    <div class="col-md-3">
                      <!--begin::Input group-->
                      <div class="fv-row mb-10">
                        <!--begin::Label-->
                        <label
                          class="form-label fs-6 fw-bolder text-dark required"
                          >{{ $t("modals.interview.name") }}</label
                        >
                        <!--end::Label-->

                        <!--begin::Input-->
                        <Field
                          class="
                            form-control form-control-md form-control-solid
                          "
                          type="text"
                          name="name"
                          autocomplete="off"
                          :placeholder="$t('modals.interview.enterName')"  
                        />
                        <!--end::Input-->
                        <div class="fv-plugins-message-container">
                          <div class="fv-help-block">
                            <ErrorMessage name="name" />
                          </div>
                        </div>
                      </div>
                      <!--end::Input group-->
                    </div>
                    <div class="col-md-3">
                      <!--begin::Input group-->
                      <div class="fv-row mb-10">
                        <!--begin::Label-->
                        <label
                          class="form-label fs-6 fw-bolder text-dark required"
                          >{{ $t("modals.interview.surname") }}</label
                        >
                        <!--end::Label-->

                        <!--begin::Input-->
                        <Field
                          class="
                            form-control form-control-md form-control-solid
                          "
                          type="text"
                          name="surname"
                          autocomplete="off"
                          :placeholder="$t('modals.interview.enterSurname')"  
                        />
                        <!--end::Input-->
                        <div class="fv-plugins-message-container">
                          <div class="fv-help-block">
                            <ErrorMessage name="surname" />
                          </div>
                        </div>
                      </div>
                      <!--end::Input group-->
                    </div>
                    <div class="col-md-5">
                      <!--begin::Input group-->
                      <div class="fv-row mb-10">
                        <!--begin::Label-->
                        <label
                          class="form-label fs-6 fw-bolder text-dark required"
                          >{{ $t("modals.interview.email") }}</label
                        >
                        <!--end::Label-->

                        <!--begin::Input-->
                        <Field
                          class="
                            form-control form-control-md form-control-solid
                          "
                          type="email"
                          name="email"
                          autocomplete="off"
                          :placeholder="$t('modals.interview.enterEmail')"                          
                          />
                        <!--end::Input-->
                        <div class="fv-plugins-message-container">
                          <div class="fv-help-block">
                            <ErrorMessage name="email" />
                          </div>
                        </div>
                      </div>
                      <!--end::Input group-->
                    </div>
                    <div class="col-md-1">
                      <!--begin::Input group-->
                      <button
                        type="submit"
                        id="kt_account_profile_details_submit"
                        ref="submitButton"
                        class="btn btn-primary mt-9 pt-3 pb-3"
                      >
                        <span class="indicator-label"> {{ $t("common.button.send") }} </span>
                        <span class="indicator-progress">
                          {{ $t("common.pleaseWait") }}
                          <span
                            class="
                              spinner-border spinner-border-sm
                              align-middle
                              ms-2
                            "
                          ></span>
                        </span>
                      </button>
                      <!--end::Input group-->
                    </div>
                  </div>
                  <div class="row mb-0">
                    <div class="col-md-12">
                      <!--begin::Input group-->
                      <div class="fv-row mb-10">
                        <!--begin::Label-->
                        <label class="form-label fs-6 fw-bolder text-dark"
                          >{{ $t("modals.interview.link") }}</label
                        >
                        <!--end::Label-->

                        <!--begin::Input-->
                        <Field
                          class="
                            form-control form-control-md form-control-solid
                          "
                          type="text"
                          name="link"
                          v-model="url"
                          autocomplete="off"
                          placeholder="Örnek link: www.asistan.ogzatech.com/interview/3253543"
                          readonly
                        />
                        <!--end::Input-->
                      </div>
                      <!--end::Input group-->
                    </div>
                  </div>
                </Form>
              </div>
              <!--end::Card body-->
            </Form>
            <!--end::Form-->
          </div>
          <!--end::Body-->
        </div>
      </div>
    </div>
    <!--end::Row-->
  
    <!--begin::Row-->
    <div class="row g-5 g-xl-8">
      <div class="col-xl-12">
        <div class="card mb-5 mb-xl-8">
          <!--begin::Body-->
          <div class="card-body pt-5">
            <!--begin::Heading-->
            <div class="text-center">
              <!--begin::Title-->
              <div class="card-label fw-bolder fs-3 mb-4 mt-2">{{ $t("modals.interview.interviewsHistorys") }}</div>
              <!--end::Title-->
            </div>
            <!--end::Heading-->
            <div class="dataTables_wrapper dt-bootstrap4 no-footer">
              <div class="table-responsive">
                <table
                  class="table align-middle table-row-dashed fs-6 gy-5 no-footer"
                  id="kt_customers_table"
                  role="grid"
                >
                  <!--begin::Table-->
                  <thead>
                    <tr
                      class="
                        text-start text-gray-400
                        fw-bolder
                        fs-7
                        text-uppercase
                        gs-0
                        text-center
                      "
                      role="row"
                    >
                      <td width="25%">{{ $t("modals.interview.nameSurname") }}</td>
                      <td width="30%">{{ $t("modals.interview.email") }}</td>
                      <td width="30%">{{ $t("modals.interview.date") }}</td>
                      <td width="15%">{{ $t("common.process") }}</td>
                    </tr>
                  </thead>
                  <tbody class="fw-bold text-gray-600 text-center">
                    <tr class="odd">
                      <td>Müslüm Bekir Kırca</td>
                      <td>muslumkirca@hotmail.com</td>
                      <td>20.05.2024 - 13:40</td>
                      <td>
                        <a
                          href="#"
                          class="
                            btn
                            btn-icon
                            btn-bg-light
                            btn-active-color-primary
                            btn-sm
                            me-1
                          "
                        >
                          <span class="svg-icon svg-icon-3"
                            ><svg
                              fill="none"
                              viewBox="0 0 24 24"
                              height="24"
                              width="24"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                xmlns="http://www.w3.org/2000/svg"
                                opacity="0.3"
                                d="M21.4 8.35303L19.241 10.511L13.485 4.755L15.643 2.59595C16.0248 2.21423 16.5426 1.99988 17.0825 1.99988C17.6224 1.99988 18.1402 2.21423 18.522 2.59595L21.4 5.474C21.7817 5.85581 21.9962 6.37355 21.9962 6.91345C21.9962 7.45335 21.7817 7.97122 21.4 8.35303ZM3.68699 21.932L9.88699 19.865L4.13099 14.109L2.06399 20.309C1.98815 20.5354 1.97703 20.7787 2.03189 21.0111C2.08674 21.2436 2.2054 21.4561 2.37449 21.6248C2.54359 21.7934 2.75641 21.9115 2.989 21.9658C3.22158 22.0201 3.4647 22.0084 3.69099 21.932H3.68699Z"
                                fill="black"
                              ></path>
                              <path
                                xmlns="http://www.w3.org/2000/svg"
                                d="M5.574 21.3L3.692 21.928C3.46591 22.0032 3.22334 22.0141 2.99144 21.9594C2.75954 21.9046 2.54744 21.7864 2.3789 21.6179C2.21036 21.4495 2.09202 21.2375 2.03711 21.0056C1.9822 20.7737 1.99289 20.5312 2.06799 20.3051L2.696 18.422L5.574 21.3ZM4.13499 14.105L9.891 19.861L19.245 10.507L13.489 4.75098L4.13499 14.105Z"
                                fill="black"
                              ></path></svg></span></a
                        ><a
                          href="#"
                          class="
                            btn
                            btn-icon
                            btn-bg-light
                            btn-active-color-primary
                            btn-sm
                          "
                          ><span class="svg-icon svg-icon-3"
                            ><svg
                              fill="none"
                              viewBox="0 0 24 24"
                              height="24"
                              width="24"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                xmlns="http://www.w3.org/2000/svg"
                                d="M5 9C5 8.44772 5.44772 8 6 8H18C18.5523 8 19 8.44772 19 9V18C19 19.6569 17.6569 21 16 21H8C6.34315 21 5 19.6569 5 18V9Z"
                                fill="black"
                              ></path>
                              <path
                                xmlns="http://www.w3.org/2000/svg"
                                opacity="0.5"
                                d="M5 5C5 4.44772 5.44772 4 6 4H18C18.5523 4 19 4.44772 19 5V5C19 5.55228 18.5523 6 18 6H6C5.44772 6 5 5.55228 5 5V5Z"
                                fill="black"
                              ></path>
                              <path
                                xmlns="http://www.w3.org/2000/svg"
                                opacity="0.5"
                                d="M9 4C9 3.44772 9.44772 3 10 3H14C14.5523 3 15 3.44772 15 4V4H9V4Z"
                                fill="black"
                              ></path></svg></span
                        ></a>
                      </td>
                    </tr>
                    <tr class="odd">
                      <td>Refiye Karakoç</td>
                      <td>karakocrefiye@hotmail.com</td>
                      <td>20.05.2024 - 09:34</td>
                      <td>
                        <a
                          href="#"
                          class="
                            btn
                            btn-icon
                            btn-bg-light
                            btn-active-color-primary
                            btn-sm
                            me-1
                          "
                        >
                          <span class="svg-icon svg-icon-3"
                            ><svg
                              fill="none"
                              viewBox="0 0 24 24"
                              height="24"
                              width="24"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                xmlns="http://www.w3.org/2000/svg"
                                opacity="0.3"
                                d="M21.4 8.35303L19.241 10.511L13.485 4.755L15.643 2.59595C16.0248 2.21423 16.5426 1.99988 17.0825 1.99988C17.6224 1.99988 18.1402 2.21423 18.522 2.59595L21.4 5.474C21.7817 5.85581 21.9962 6.37355 21.9962 6.91345C21.9962 7.45335 21.7817 7.97122 21.4 8.35303ZM3.68699 21.932L9.88699 19.865L4.13099 14.109L2.06399 20.309C1.98815 20.5354 1.97703 20.7787 2.03189 21.0111C2.08674 21.2436 2.2054 21.4561 2.37449 21.6248C2.54359 21.7934 2.75641 21.9115 2.989 21.9658C3.22158 22.0201 3.4647 22.0084 3.69099 21.932H3.68699Z"
                                fill="black"
                              ></path>
                              <path
                                xmlns="http://www.w3.org/2000/svg"
                                d="M5.574 21.3L3.692 21.928C3.46591 22.0032 3.22334 22.0141 2.99144 21.9594C2.75954 21.9046 2.54744 21.7864 2.3789 21.6179C2.21036 21.4495 2.09202 21.2375 2.03711 21.0056C1.9822 20.7737 1.99289 20.5312 2.06799 20.3051L2.696 18.422L5.574 21.3ZM4.13499 14.105L9.891 19.861L19.245 10.507L13.489 4.75098L4.13499 14.105Z"
                                fill="black"
                              ></path></svg></span></a
                        ><a
                          href="#"
                          class="
                            btn
                            btn-icon
                            btn-bg-light
                            btn-active-color-primary
                            btn-sm
                          "
                          ><span class="svg-icon svg-icon-3"
                            ><svg
                              fill="none"
                              viewBox="0 0 24 24"
                              height="24"
                              width="24"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                xmlns="http://www.w3.org/2000/svg"
                                d="M5 9C5 8.44772 5.44772 8 6 8H18C18.5523 8 19 8.44772 19 9V18C19 19.6569 17.6569 21 16 21H8C6.34315 21 5 19.6569 5 18V9Z"
                                fill="black"
                              ></path>
                              <path
                                xmlns="http://www.w3.org/2000/svg"
                                opacity="0.5"
                                d="M5 5C5 4.44772 5.44772 4 6 4H18C18.5523 4 19 4.44772 19 5V5C19 5.55228 18.5523 6 18 6H6C5.44772 6 5 5.55228 5 5V5Z"
                                fill="black"
                              ></path>
                              <path
                                xmlns="http://www.w3.org/2000/svg"
                                opacity="0.5"
                                d="M9 4C9 3.44772 9.44772 3 10 3H14C14.5523 3 15 3.44772 15 4V4H9V4Z"
                                fill="black"
                              ></path></svg></span
                        ></a>
                      </td>
                    </tr>
                    <tr class="odd">
                      <td>Buse Çallı</td>
                      <td>busecalli25@gmail.com</td>
                      <td>14.05.2024 - 10:05</td>
                      <td>
                        <a
                          href="#"
                          class="
                            btn
                            btn-icon
                            btn-bg-light
                            btn-active-color-primary
                            btn-sm
                            me-1
                          "
                        >
                          <span class="svg-icon svg-icon-3"
                            ><svg
                              fill="none"
                              viewBox="0 0 24 24"
                              height="24"
                              width="24"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                xmlns="http://www.w3.org/2000/svg"
                                opacity="0.3"
                                d="M21.4 8.35303L19.241 10.511L13.485 4.755L15.643 2.59595C16.0248 2.21423 16.5426 1.99988 17.0825 1.99988C17.6224 1.99988 18.1402 2.21423 18.522 2.59595L21.4 5.474C21.7817 5.85581 21.9962 6.37355 21.9962 6.91345C21.9962 7.45335 21.7817 7.97122 21.4 8.35303ZM3.68699 21.932L9.88699 19.865L4.13099 14.109L2.06399 20.309C1.98815 20.5354 1.97703 20.7787 2.03189 21.0111C2.08674 21.2436 2.2054 21.4561 2.37449 21.6248C2.54359 21.7934 2.75641 21.9115 2.989 21.9658C3.22158 22.0201 3.4647 22.0084 3.69099 21.932H3.68699Z"
                                fill="black"
                              ></path>
                              <path
                                xmlns="http://www.w3.org/2000/svg"
                                d="M5.574 21.3L3.692 21.928C3.46591 22.0032 3.22334 22.0141 2.99144 21.9594C2.75954 21.9046 2.54744 21.7864 2.3789 21.6179C2.21036 21.4495 2.09202 21.2375 2.03711 21.0056C1.9822 20.7737 1.99289 20.5312 2.06799 20.3051L2.696 18.422L5.574 21.3ZM4.13499 14.105L9.891 19.861L19.245 10.507L13.489 4.75098L4.13499 14.105Z"
                                fill="black"
                              ></path></svg></span></a
                        ><a
                          href="#"
                          class="
                            btn
                            btn-icon
                            btn-bg-light
                            btn-active-color-primary
                            btn-sm
                          "
                          ><span class="svg-icon svg-icon-3"
                            ><svg
                              fill="none"
                              viewBox="0 0 24 24"
                              height="24"
                              width="24"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                xmlns="http://www.w3.org/2000/svg"
                                d="M5 9C5 8.44772 5.44772 8 6 8H18C18.5523 8 19 8.44772 19 9V18C19 19.6569 17.6569 21 16 21H8C6.34315 21 5 19.6569 5 18V9Z"
                                fill="black"
                              ></path>
                              <path
                                xmlns="http://www.w3.org/2000/svg"
                                opacity="0.5"
                                d="M5 5C5 4.44772 5.44772 4 6 4H18C18.5523 4 19 4.44772 19 5V5C19 5.55228 18.5523 6 18 6H6C5.44772 6 5 5.55228 5 5V5Z"
                                fill="black"
                              ></path>
                              <path
                                xmlns="http://www.w3.org/2000/svg"
                                opacity="0.5"
                                d="M9 4C9 3.44772 9.44772 3 10 3H14C14.5523 3 15 3.44772 15 4V4H9V4Z"
                                fill="black"
                              ></path></svg></span
                        ></a>
                      </td>
                    </tr>
                  </tbody>
                  <!--end::Table-->
                </table>
              </div>
            </div>
          </div>
          <!--end::Body-->
        </div>
      </div>
    </div>
    <!--end::Row-->
  </template>
  
  <script lang="ts">
  import { defineComponent, onMounted, ref } from "vue";
  import { setCurrentPageTitle } from "@/presentation/helper/Breadcrumb";
  import { ErrorMessage, Field, Form } from "vee-validate";
import * as Yup from "yup";
import { useI18n } from "vue-i18n";

  export default defineComponent({
    name: "interview",
    components: {
      Field,
      Form,
      ErrorMessage,
    },
    setup() {
      const { t } = useI18n();
      const url = ref("");

    //Create form validation object
    const firstForm = Yup.object().shape({
      name: Yup.string().required(t("validators_error.required")).label("Name"),
      surname: Yup.string()
        .required(t("validators_error.required"))
        .label("Surname"),
      email: Yup.string()
        .required(t("validators_error.required"))
        .email(t("validators_error.email"))
        .label("Email"),
    });
    
    const submit = (values: any) => {
      console.log(values);
      url.value = "https://www.asistan.ogzatech.com/interview/3253543"
    };

      onMounted(() => {
        setCurrentPageTitle(t("modals.interview.title"));
      });
  
      return {
        firstForm,
        submit,
        url
      };
    },
  });
  </script>
  